<template>
   <AdminPanelDialog
      ref="dialog"
      @close="$refs.form.reset()"
      :title="title"
      :action="sendRequest"
      :actionBtnText="actionBtnText"
      :isActionBtnDisabled="!isFormValid"
   >
      <v-form v-model="isFormValid" ref="form" @submit="sendRequest">
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            type="text"
            :counter="couponConsts.couponLength"
            :rules="[rules.coupon]"
            v-model="formField.coupon"
            label="Coupon/promo code"
         ></v-text-field>
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            type="number"
            min="0"
            :rules="[rules.discount]"
            v-model="formField.weekly"
            label="Weekly"
         ></v-text-field>
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            min="0"
            type="number"
            :rules="[rules.discount]"
            v-model="formField.monthly"
            label="Monthly"
         ></v-text-field>
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            type="number"
            min="0"
            :rules="[rules.discount]"
            v-model="formField.half_yearly"
            label="Half-yearly"
         ></v-text-field>
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            type="date"
            :rules="[rules.required]"
            v-model="formField.start_date"
            label="Start Date"
         ></v-text-field>
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            type="date"
            :rules="[rules.required]"
            v-model="formField.end_date"
            label="End Date"
         ></v-text-field>
         <v-text-field
            class="admin-panel__dashboard__dialog__body__item"
            type="number"
            :rules="[rules.cycle]"
            v-model="formField.cycles"
            label="Cycle count"
            min="0"
         ></v-text-field>
         <v-select
            class="admin-panel__dashboard__dialog__body__item"
            :items="[
               { text: '$', value: '$' },
               { text: '%', value: '%' },
            ]"
            v-model="formField.type_of_discount"
            label="Type of discount"
         ></v-select>
      </v-form>
   </AdminPanelDialog>
</template>

<script>
import AdminPanelDialog from '../AdminPanelDialog.vue';
import { couponValidation, couponConsts } from '../../../utils/formValidation';
export default {
   components: { AdminPanelDialog },
   data() {
      return {
         actionBtnText: 'Create',
         isFormValid: false,
         formField: {
            coupon: '',
            weekly: null,
            monthly: null,
            half_yearly: null,
            start_date: null,
            end_date: null,
            cycles: null,
            type_of_discount: '$',
         },
         rules: couponValidation,
         title: '',
      };
   },
   computed: {
      isEdit() {
         return this.actionBtnText.toLowerCase() === 'edit';
      },

      couponConsts() {
         return couponConsts;
      },
   },

   methods: {
      func(val) {
         const ready = val.replace('/^[a-zA-Z!@#$%^&*)(+=._-]+$/g', '');
         console.log(ready);
      },
      openDialog({ item, text, title }) {
         if (item) {
            this.formField = { ...item };
         }
         this.title = title;
         this.actionBtnText = text;
         this.$refs.dialog.dialogOpen();
      },

      async sendRequest() {
         this.$refs.form.validate();
         if (!this.isFormValid) return;
         try {
            await this.$http(`/admin/resources/coupon/${this.isEdit ? this.formField._id : ''}`, { ...this.formField });
         } catch (e) {
            console.log(e); //TODO: handle error
         } finally {
            this.$store.dispatch('admin/loadResources');
         }

         this.$refs.form.reset();
      },
   },
};
</script>

<style>
.v-text-field input::-webkit-outer-spin-button,
.v-text-field input::-webkit-inner-spin-button {
   -webkit-appearance: none !important;
   margin: 0 !important;
}

.v-text-field input[type='number'] {
   -moz-appearance: textfield !important;
}
</style>

<style lang="scss">
.v-sheet.v-list {
   color: black;
   background: var(--green-lighten-4) !important;
}
.v-list .v-list-item--active {
   color: black !important;
   font-weight: bold;
}
</style>