<template>
   <div style="overflow: auto">
      <AdminPanelItem @searchUpdated="value => (search = value)" :hasCreate="false">
         <template v-slot:additionalHeader>
            <v-btn
               class="admin-panel__dasboard__item__title-btn"
               :disabled="isFileDowloading"
               :loading="isFileDowloading"
               @click="exportXLS"
            >
               Export Users to XLSX file
            </v-btn>
         </template>
         <v-data-table
            class="admin-panel__dasboard__item__table"
            :headers="headersWithWidth"
            :items="itemsWithDateFieldLocal"
            :search="search"
            :loading="isLoading"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <div class="actions">
                  <v-icon dense @click="deleteItem(item)"> mdi-delete </v-icon>
               </div>
            </template>
         </v-data-table>
      </AdminPanelItem>

      <AdminPanelDashboardDialogDelete ref="delete" />
   </div>
</template>

<script>
import AdminPanelDashboardDialogDelete from '../AdminPanelDashboardDialogDelete.vue';
import AdminPanelItem from '../AdminPanelItem.vue';
import remoteOrigin from '../../../store/remoteOrigin';
export default {
   components: {
      AdminPanelItem,
      AdminPanelDashboardDialogDelete,
   },
   data() {
      return {
         headers: [
            { text: 'Discount', value: 'discount' },
            { text: 'First Name', value: 'first_name' },
            { text: 'Last Name', value: 'last_name' },
            { text: 'Email', value: 'email' },
            { text: 'Phone', value: 'phone' },
            { text: 'Provider', value: 'provider' },
            { text: 'Verified Email', value: 'verify_email' },
            { text: 'Verified Phone', value: 'verify_phone' },
            { text: 'Subscription', value: 'subscription.name' },
            { text: 'Subscription Time', value: 'subscription.subscribtion_time' },
            { text: 'Actions', value: 'actions', class: 'text-center' },
         ],
         search: '',
         isFileDowloading: false,
      };
   },

   computed: {
      isLoading() {
         return this.$store.getters['admin/getLoading'];
      },

      items() {
         return this.$store.getters['admin/getResources']['user'];
      },

      itemsWithDateFieldLocal() {
         if (!this.items) return;
         return [...this.items].map(item => {
            if (item?.subscription) {
               return {
                  ...item,
                  subscription: {
                     ...item.subscription,
                     subscribtion_time: new Date(Date.parse(item.subscription.subscribtion_time)).toLocaleString(),
                  },
               };
            }
            return item;
         });
      },

      headersWithWidth() {
         return this.headers.map(header => ({ ...header, width: `${100 / this.headers.length}%` }));
      },
   },
   methods: {
      deleteItem(item) {
         this.$refs.delete.dialogOpen({ name: 'delete', schema: item });
      },
      exportXLS() {
         this.isFileDowloading = true;
         this.$http
            .post('resources/export/users')
            .then(response => {
               const url = `${remoteOrigin}:5000/${response.data.filename}`;
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', response.data.filename);
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
            })
            .catch(response => {
               console.error('Could not Download the Excel report from the backend.', response);
            })
            .finally(() => {
               this.isFileDowloading = false;
            });
      },
   },
};
</script>

<style lang="scss">
.actions {
   display: flex;
   justify-content: center;
   align-items: center;
}
</style>
