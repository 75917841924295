<template>
   <div style="width: 100%; overflow: auto">
      <component v-if="Object.keys(component).includes(routeName)" v-bind:is="component[routeName]"></component>

      <v-card v-else class="admin-panel__dasboard__item">
         <v-card-title class="admin-panel__dasboard__item__title">
            <v-text-field
               class="admin-panel__dasboard__item__title-search"
               v-model="search"
               append-icon="mdi-magnify"
               label="Search"
               single-line
               hide-details
               v-if="!isRouterGeneral"
            ></v-text-field>
            <v-btn
               class="admin-panel__dasboard__item__title-btn"
               v-if="!isRouterGeneral && !isRouterUser && routeName !== 'categories'"
               @click="createItem"
            >
               {{ textBtn }}
            </v-btn>

            <div v-for="(button, i) in headerButtons" :key="i">
               <v-btn class="admin-panel__dasboard__item__title-btn" @click="buttonAction(button)">
                  {{ buttonName(button) }}
               </v-btn>
            </div>
         </v-card-title>

         <v-data-table
            v-if="!isRouterGeneral"
            class="admin-panel__dasboard__item__table"
            :headers="headers['usual']"
            :items="items['usual']"
            :search="search"
            :loading="loading"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <span v-for="(button, i) in tableButtons" :key="i">
                  <v-icon class="upload" dense @click="buttonAction(button, item)">
                     mdi-arrow-up-bold-box-outline
                  </v-icon>
               </span>
               <span><v-icon dense @click="editItem(item)"> mdi-pencil </v-icon></span>
               <span><v-icon dense @click="deleteItem(item)" v-if="!isRouterGeneral"> mdi-delete </v-icon></span>
            </template>
         </v-data-table>

         <!--  table for discount -->
         <h2 v-if="isRouterGeneral">Discount</h2>
         <v-data-table
            v-if="isRouterGeneral"
            class="admin-panel__dasboard__item__table"
            :headers="headers['discount']"
            :items="items['discount']"
            :search="search"
            :loading="loading"
            :hide-default-footer="routeName === 'generals'"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <v-icon dense @click="editItem(item)"> mdi-pencil </v-icon>
               <v-icon dense @click="deleteItem(item)" v-if="!isRouterGeneral"> mdi-delete </v-icon>
            </template>
         </v-data-table>

         <!--  table for subscriptions -->
         <h2 v-if="isRouterGeneral" class="subscriptions-table">Subscriptions</h2>
         <v-data-table
            v-if="isRouterGeneral"
            class="admin-panel__dasboard__item__table"
            :headers="headers['subscriptions']"
            :items="items['subscriptions']"
            :search="search"
            :loading="loading"
            :hide-default-footer="routeName === 'generals'"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <v-icon dense @click="editItem(item)"> mdi-pencil </v-icon>
               <v-icon dense @click="deleteItem(item)" v-if="!isRouterGeneral"> mdi-delete </v-icon>
            </template>
         </v-data-table>
         <AdminPanelDashboardDialogCreate ref="create" />
         <AdminPanelDashboardDialogDelete ref="delete" />
         <AdminPanelDashboardDialogEdit ref="edit" />
         <AdminPanelDashboardDialogFile ref="upload" />
      </v-card>
   </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import AdminPanelDashboardDialogDelete from '../../components/AdminPanel/AdminPanelDashboardDialogDelete.vue';
import AdminPanelDashboardDialogEdit from '../../components/AdminPanel/AdminPanelDashboardDialogEdit.vue';
import AdminPanelDashboardDialogCreate from '../../components/AdminPanel/AdminPanelDashboardDialogCreate.vue';
import AdminPanelDashboardDialogFile from '../../components/AdminPanel/AdminPanelDashboardDialogFile.vue';
import AdminPanelDasboardUsers from '../../components/AdminPanel/AdminUsers/AdminPanelDasboardUsers.vue';
import AdminPanelDasboardCoupon from '../../components/AdminPanel/AdminCoupon/AdminPanelDasboardCoupon.vue';
import AdminPanelDasboardDiscount from '../../components/AdminPanel/AdminDiscount/AdminPanelDasboardDiscount.vue';
import AdminPanelDasboardQuiz from '../../components/AdminPanel/AdminQuiz/AdminPanelDasboardQuiz.vue';
import AdminHome from '../../components/AdminPanel/AdminHome.vue';

export default {
   name: 'AdminPanelDasboardItem',
   components: {
      AdminPanelDashboardDialogDelete,
      AdminPanelDashboardDialogCreate,
      AdminPanelDashboardDialogEdit,
      AdminPanelDashboardDialogFile,
      AdminPanelDasboardCoupon,
      AdminPanelDasboardUsers,
      AdminPanelDasboardDiscount,
      AdminHome,
      AdminPanelDasboardQuiz,
   },
   inject: ['checkIfErrorIsTokenNotFound', 'loadResources'],

   data() {
      return {
         search: '',
         headers: { usual: [], subscriptions: [], discount: [] },
         items: { usual: [], subscriptions: [], discount: [] },
         data: this.resources,

         component: {
            coupon: 'AdminPanelDasboardCoupon',
            home: 'AdminHome',
            user: 'AdminPanelDasboardUsers',
            discount: 'AdminPanelDasboardDiscount',
            questions: 'AdminPanelDasboardQuiz',
         },
      };
   },

   computed: {
      routeName() {
         return this.$route.params.name;
      },

      resources() {
         return this.$store.getters['admin/getResources'];
      },

      settings() {
         return this.$store.getters['admin/getSettings'];
      },

      loading() {
         return this.$store.getters['admin/getLoading'];
      },

      isRoutNamePlural() {
         return this.routeName[this.routeName.length - 1] === 's';
      },

      isRouterGeneral() {
         return this.routeName === 'generals';
      },

      isRouterUser() {
         return this.routeName === 'user';
      },

      moduleSettings() {
         return this.settings[this.routeName];
      },

      headerButtons() {
         return this.moduleSettings != undefined ? this.moduleSettings['buttons']['header'] : [];
      },

      tableButtons() {
         return this.moduleSettings != undefined ? this.moduleSettings['buttons']['table'] : [];
      },

      textBtn() {
         return this.isRoutNamePlural
            ? 'Create ' + this.routeName.slice(0, this.routeName.length - 1)
            : 'Create ' + this.routeName;
      },

      /*buttonName() {
         return this.isRoutNamePlural
            ? ' Upload ' + this.routeName.slice(0, this.routeName.length - 1)
            : ' Upload ' + this.routeName;
      },*/
   },

   watch: {
      resources() {
         this.loadData();
      },
   },

   methods: {
      loadData() {
         this.data = this.resources;

         this.formUsualItems(this.routeName);
         this.formGeneralData();
      },

      buttonName(button) {
         return button.label ? button.label : ' Action ' + this.routeName;
      },

      buttonAction(button, item = {}) {
         const data = {
            item: item,
            button: button,
         };
         this.$refs.upload.dialogOpen(data);
      },

      createItem() {
         const schema = Object.assign({}, this.data[this.routeName.toLowerCase()][0]);
         delete schema._id;
         Object.keys(schema).map(key => {
            schema[key] = '';
         });

         const data = {
            name: 'create',
            schema: schema,
         };
         this.$refs.create.dialogOpen(data);
      },

      editItem(item) {
         const schema = cloneDeep(item);
         if (this.routeName === 'admins' || this.routeName === 'users') {
            schema.password = '';
         }
         const data = {
            name: 'edit',
            schema: schema,
         };
         this.$refs.edit.dialogOpen(data);
      },

      deleteItem(item) {
         const data = {
            name: 'delete',
            schema: cloneDeep(item),
         };
         this.$refs.delete.dialogOpen(data);
      },

      formGeneralData() {
         const itemHeader = (width, text, value) => {
            return {
               width: width + '%',
               text: text,
               value: value,
            };
         };

         this.headers['discount'] = [
            {
               text: 'Discount',
               value: 'discount',
               width: 50 + '%',
            },
            this.actionHeader(50),
         ];

         this.items.discount = [
            {
               _id: this.data['generals'][0]._id,
               discount: this.data['generals'][0].discount,
            },
         ];

         this.headers.subscriptions = [
            itemHeader(25, 'Trial subs price', 'trial'),
            itemHeader(25, 'One month price', 'one_month'),
            itemHeader(25, 'Two months price', 'two_month'),
            this.actionHeader(25),
         ];

         this.items.subscriptions = [
            {
               _id: this.data['generals'][0]._id,
               trial: this.data['generals'][0].trial,
               one_month: this.data['generals'][0].one_month,
               two_month: this.data['generals'][0].two_month,
            },
         ];
      },

      formUsualItems(route) {
         this.headers.usual = [];
         this.items.usual = [];
         for (const item in this.data) {
            if (item.toLowerCase() === route) {
               for (const itm in this.data[item][0]) {
                  if (itm !== '_id') {
                     this.headers.usual.push({
                        text: itm,
                        value: itm,
                        width: this.data[item].length / 100 + '%',
                     });
                  }
               }
               this.items.usual = this.data[item];
               this.headers.usual.push(this.actionHeader(this.data[item].length / 100));
               break;
            }
         }
      },

      actionHeader(width) {
         return {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            width: width + '%',
         };
      },
   },

   beforeRouteUpdate(to, from, next) {
      this.formUsualItems(to.params.name);
      next();
   },
};
</script>

<style lang="scss">
.admin-panel__dasboard__item {
   width: 100%;
   height: 100%;
   overflow-y: auto;
   background-color: var(--green-lighten-4) !important;
   padding: 20px 40px;
   &__title {
      &-search {
         font-family: 'Roboto';
         font-weight: 500;
         font-size: 16px;
      }
      &-btn {
         height: 40px !important;
         margin-left: 10px;
         background-color: var(--green-lighten-3) !important;
         color: var(--grey-darken-4) !important;
         font-family: 'Roboto' !important;
         font-size: 16px !important;
         font-weight: 700 !important;
      }
   }
   &__table {
      overflow-wrap: anywhere;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
   }
}

.mdi-delete::before,
.mdi-pencil::before,
.v-icon.v-icon--dense,
.theme--light.v-icon:focus:after {
   font-size: 16px !important;
}

.v-icon.v-icon.v-icon--link:last-child {
   margin-right: 5px;
}

.v-icon.upload {
   margin-left: -5px !important;
}

.subscriptions-table {
   margin-top: 80px;
}

.v-application th.text-center {
   text-align: center !important;
   & span {
      margin-left: 8px;
   }
}
</style>
