<template>
   <v-dialog v-model="open" max-width="500" persistent>
      <div class="admin-panel__dashboard__dialog">
         <p class="admin-panel__dashboard__dialog__title">Are you sure you want to delete the selected entry?</p>
         <div class="admin-panel__dashboard__dialog__footer">
            <v-btn class="admin-panel__dashboard__dialog__footer-btn" @click="open = false"> CANCEL </v-btn>
            <v-btn class="admin-panel__dashboard__dialog__footer-btn" @click="action">
               {{ data.name }}
            </v-btn>
         </div>
      </div>
   </v-dialog>
</template>

<script>
export default {
   name: 'AdminPanelDashboardDialog',
   data() {
      return {
         open: false,
         data: {
            name: '',
            schema: {},
         },
      };
   },
   computed: {
      routeName() {
         return this.$route.params.name;
      },

      isRoutNamePlural() {
         return this.routeName[this.routeName.length - 1] === 's';
      },

      titleText() {
         return this.isRoutNamePlural
            ? `Delete ${this.routeName.slice(0, this.routeName.length - 1)}`.toUpperCase()
            : `Delete ${this.routeName}`.toUpperCase();
      },

      currentRoute() {
         return this.$route.params.name.toLowerCase();
      },
   },
   methods: {
      dialogOpen(data) {
         this.data = data;
         this.open = true;
         console.log(data);
      },
      async action() {
         try {
            await this.$http.delete(
               `/admin/resources/${
                  this.$route.params.name === 'subscribtion' ? 'subscription' : this.$route.params.name
               }/${this.data.schema._id}`,
            );
            this.open = false;
         } catch (error) {
            console.log(error.response?.data?.message);
         } finally {
            this.$store.dispatch('admin/loadResources');
         }
      },
   },
};
</script>

<style lang="scss">
.admin-panel__dashboard__dialog {
   width: 100%;
   height: auto !important;
   padding: 30px !important;
   background-color: var(--green-lighten-4) !important;
   &__title {
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
   }
   &__body {
      width: 100%;
      &__item {
         font-family: 'Roboto';
         font-size: 16px;
         font-weight: 500;
      }
   }
   &__footer {
      display: flex;
      justify-content: space-between;

      align-items: center;
      margin-top: 10px;
      &-btn {
         background-color: var(--green-lighten-2) !important;
      }
   }
}
</style>