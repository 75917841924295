<template>
   <div style="width: 100%; overflow: auto">
      <v-card class="admin-panel__dasboard__item">
         <v-card-title>
            <AdminPanelSearch :search.sync="search" v-if="hasSearch" />
            <v-btn class="admin-panel__dasboard__item__title-btn" @click="createItem" v-if="hasCreate"> Create </v-btn>
            <slot name="additionalHeader"></slot>
         </v-card-title>
         <slot></slot>
      </v-card>
   </div>
</template>

<script>
import AdminPanelSearch from './AminPanelSearch.vue';

export default {
   name: 'AdminPanelDasboardCoupon',
   components: {
      AdminPanelSearch,
   },
   props: {
      hasSearch: { type: Boolean, default: true },
      hasCreate: { type: Boolean, default: true },
   },

   data() {
      return {
         search: '',
      };
   },

   computed: {
      tableHeaders() {
         return this.hasEdit || this.hasDelete
            ? [...this.headers, { text: 'Actions', value: 'actions' }]
            : this.headers;
      },
   },

   watch: {
      search() {
         this.$emit('searchUpdated', this.search);
      },
   },

   methods: {
      createItem() {
         this.$emit('create');
      },
   },
};
</script>

<style lang="scss">
.admin-panel__dasboard__item {
   width: 100%;
   height: 100%;
   overflow-y: auto;
   background-color: var(--green-lighten-4) !important;
   padding: 20px 40px;
   &__title {
      &-search {
         font-family: 'Roboto';
         font-weight: 500;
         font-size: 16px;
      }
      &-btn {
         height: 40px !important;
         margin-left: 10px;
         background-color: var(--green-lighten-3) !important;
         color: var(--grey-darken-4) !important;
         font-family: 'Roboto' !important;
         font-size: 16px !important;
         font-weight: 700 !important;
      }
   }
   &__table {
      overflow-wrap: anywhere;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      margin-top: 20px;
      width: 100%;
   }
}

.mdi-delete::before,
.mdi-pencil::before,
.v-icon.v-icon--dense,
.theme--light.v-icon:focus:after {
   font-size: 16px !important;
}

.v-icon.v-icon.v-icon--link:last-child {
   margin-right: 5px;
}

.v-icon.upload {
   margin-left: -5px !important;
}

.subscriptions-table {
   margin-top: 80px;
}
</style>
