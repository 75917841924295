<template>
   <div style="overflow: auto">
      <AdminPanelItem @searchUpdated="value => (search = value)" :hasCreate="false" :hasSearch="tab == 1">
         <v-tabs v-model="tab">
            <v-tab>Discount</v-tab>
            <v-tab>History</v-tab>
         </v-tabs>

         <v-tabs-items v-model="tab">
            <v-tab-item>
               <v-data-table
                  class="admin-panel__dasboard__item__table"
                  :headers="headers"
                  :items="itemsFixedData"
                  :loading="isLoading"
                  :search="search"
                  hide-default-footer
               >
                  <template v-slot:[`item.actions`]="{ item }">
                     <div class="actions">
                        <v-icon dense @click="openEditForm(item)"> mdi-pencil </v-icon>
                     </div>
                  </template>
               </v-data-table>
            </v-tab-item>
            <v-tab-item>
               <v-data-table
                  class="admin-panel__dasboard__item__table"
                  :headers="headersHistory"
                  :items="itemsHistoryFixedDate"
                  :search="search"
                  :loading="isLoading"
                  :sort-by="'updatedAt'"
                  :sort-desc="true"
               />
            </v-tab-item>
         </v-tabs-items>
      </AdminPanelItem>

      <AdminPanelDashboardDialogEdit ref="edit" />
   </div>
</template>

<script>
import AdminPanelDashboardDialogEdit from '../AdminPanelDashboardDialogEdit.vue';
import AdminPanelItem from '../AdminPanelItem.vue';
export default {
   components: {
      AdminPanelItem,
      AdminPanelDashboardDialogEdit,
   },
   data() {
      return {
         headers: [
            { text: 'Discount', value: 'discount' },
            { text: 'Date of Last Update', value: 'updatedAt' },
            { text: 'Actions', value: 'actions', class: 'text-center' },
         ],
         headersHistory: [
            { text: 'Discount', value: 'discount' },
            { text: 'Date of Last Update', value: 'updatedAt' },
         ],
         search: '',
         tab: 0,
      };
   },

   computed: {
      isLoading() {
         return this.$store.getters['admin/getLoading'];
      },

      items() {
         return this.$store.getters['admin/getResources']['discount'];
      },

      itemsHistory() {
         return this.$store.getters['admin/getResources']['history_discount'];
      },

      itemsHistoryFixedDate() {
         if (!this.itemsHistory) return;
         return this.itemsHistory.map(item => ({
            _id: item.id,
            discount: item.discount,
            updatedAt: new Date(Date.parse(item.updatedAt)),
         }));
      },

      itemsFixedData() {
         if (!this.items) return;
         return this.items.map(item => ({
            _id: item._id,
            discount: item.discount,
            updatedAt: new Date(Date.parse(item.updatedAt)),
         }));
      },
   },
   methods: {
      openEditForm(item) {
         this.$refs.edit.dialogOpen({ schema: { discount: item.discount }, text: 'Save' });
      },
   },
};
</script>

<style lang="scss">
.actions {
   display: flex;
   justify-content: center;
   align-items: center;
}
</style>
