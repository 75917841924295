export const couponValidation = {
      coupon: value =>
         (value && value.length < couponConsts.couponLength && !!value.match(/^[a-zA-Z0-9_.-]*$/)) ||
         `Max length is ${couponConsts.couponLength} characters and contains only letters and digits`,
      discount: value => (value < couponConsts.maxDiscount && value > 0) && !value.match(/^[+-.]?\d+(\.\d+)?$/) || `Range of value is 0-${couponConsts.maxDiscount}`,
      cycle: value => (value < couponConsts.maxCycle  && value > 0) && !value.match(/^[+-.]?\d+(\.\d+)?$/)   || `Range of value is 0-${couponConsts.maxCycle}`,
      required: value => !!value || 'Required',
}
export const couponConsts ={
	couponLength: 32,
	maxDiscount: 100,
	maxCycle: 10000
}

