<template>
   <div style="overflow: auto">
      <AdminPanelItem @create="openCreaateForm" @searchUpdated="value => (search = value)">
         <v-data-table
            class="admin-panel__dasboard__item__table"
            :headers="headers"
            :items="items"
            :search="search"
            :loading="isLoading"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <div>
                  <v-icon dense @click="openEditForm(item)"> mdi-pencil </v-icon>
                  <v-icon dense @click="deleteItem(item)"> mdi-delete </v-icon>
               </div>
            </template>
         </v-data-table>
      </AdminPanelItem>

      <AdminPanelDashboardDialogEdit ref="edit" />
      <AdminPanelDashboardDialogCreate ref="create" />
      <AdminPanelDashboardDialogDelete ref="delete" />
   </div>
</template>

<script>
import AdminPanelDashboardDialogDelete from '../AdminPanelDashboardDialogDelete.vue';
import AdminPanelDashboardDialogCreate from '../AdminPanelDashboardDialogCreate.vue';
import AdminPanelDashboardDialogEdit from '../AdminPanelDashboardDialogEdit.vue';
import AdminPanelItem from '../AdminPanelItem.vue';
export default {
   components: {
      AdminPanelItem,
      AdminPanelDashboardDialogDelete,
      AdminPanelDashboardDialogEdit,
      AdminPanelDashboardDialogCreate,
   },
   data() {
      return {
         headers: [
            { text: 'Question', value: 'text', width: '45%' },
            { text: 'Comment to question', value: 'comments', width: '45%' },
            { text: 'Actions', value: 'actions' },
         ],
         search: '',
      };
   },

   methods: {
      openEditForm(item) {
         this.$refs.edit.dialogOpen({ name: 'Save', schema: { ...item } });
      },

      openCreaateForm() {
         this.$refs.create.dialogOpen({ name: 'Save', schema: { text: '', type: '', comments: '' } });
      },

      deleteItem(item) {
         this.$refs.delete.dialogOpen({ name: 'delete', schema: item });
      },
   },
   computed: {
      isLoading() {
         return this.$store.getters['admin/getLoading'];
      },

      items() {
         return this.$store.getters['admin/getResources']['questions'];
      },
   },
};
</script>

<style>
</style>