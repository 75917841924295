<template>
   <v-text-field
      class="admin-panel__dasboard__item__title-search"
      :value="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      @input="updateSearch"
   ></v-text-field>
</template>
<script>
export default {
   props: {
      search: String,
   },
   methods: {
      updateSearch(value) {
         this.$emit('update:search', value);
      },
   },
};
</script>

<style>
</style>