<template>
   <div style="overflow: auto">
      <AdminPanelItem @create="openCreaateForm" @searchUpdated="value => (search = value)">
         <v-data-table
            class="admin-panel__dasboard__item__table"
            :headers="headers"
            :items="items"
            :search="search"
            :loading="isLoading"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <div>
                  <v-icon dense @click="openEditForm(item)"> mdi-pencil </v-icon>
                  <v-icon dense @click="deleteItem(item)"> mdi-delete </v-icon>
               </div>
            </template>
         </v-data-table>
      </AdminPanelItem>

      <AdminCouponForm ref="formDialog" />
      <AdminPanelDashboardDialogDelete ref="delete" />
   </div>
</template>

<script>
import AdminCouponForm from './AdminCouponForm.vue';
import AdminPanelDashboardDialogDelete from '../AdminPanelDashboardDialogDelete.vue';
import AdminPanelItem from '../AdminPanelItem.vue';
export default {
   components: { AdminPanelItem, AdminCouponForm, AdminPanelDashboardDialogDelete },
   data() {
      return {
         headers: [
            { text: 'Coupon/promo code', value: 'coupon' },
            { text: 'Weekly', value: 'weekly' },
            { text: 'Monthly', value: 'monthly' },
            { text: 'Half-yearly', value: 'half_yearly' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Cycle count', value: 'cycles' },
            { text: 'Type of discount', value: 'type_of_discount' },
            { text: 'Actions', value: 'actions' },
         ],
         search: '',
      };
   },

   methods: {
      openEditForm(item) {
         this.$refs.formDialog.openDialog({ item, text: 'Save', title: 'Edit Coupon' });
      },

      openCreaateForm() {
         this.$refs.formDialog.openDialog({ text: 'Save', title: 'Create Coupon' });
      },
      deleteItem(item) {
         this.$refs.delete.dialogOpen({ name: 'delete', schema: item });
      },
   },
   computed: {
      isLoading() {
         return this.$store.getters['admin/getLoading'];
      },

      items() {
         return (
            this.$store.getters['admin/getResources']['coupon'] || [
               //TODO: remove mock data when it will be from server
               {
                  _id: 123,
                  coupon: 'ADDSAD1',
                  weekly: 10,
                  monthly: 21,
                  half_yearly: 21,
                  start_date: '',
                  end_date: '',
                  cycles: 20,
                  type_of_discount: '$',
               },
               {
                  _id: 32,
                  coupon: 'SSDA12',
                  weekly: 2,
                  monthly: 3,
                  half_yearly: 4,
                  start_date: '',
                  end_date: '',
                  cycles: 5,
                  type_of_discount: '%',
               },
            ]
         );
      },
   },
};
</script>

<style>
</style>