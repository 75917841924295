<template>
   <div class="home">
      <h2 class="home__title">Menu</h2>
      <div class="home__list">
         <template v-for="menu in menuItems">
            <router-link
               v-if="menu.path !== 'home'"
               :key="menu.path"
               :to="{ name: 'dashboard-item', params: { name: menu.path } }"
               class="home__item"
               >{{ menu.text }}</router-link
            >
         </template>
      </div>
   </div>
</template>

<script>
export default {
   computed: {
      menuItems() {
         return this.$store.getters['admin/getMenuItems'];
      },
   },
};
</script>

<style lang="scss" scoped>
.home {
   padding: 20px;
   &__title {
      text-align: center;
   }

   &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: start;
   }
   &__item {
      flex-basis: 33.333%;
      margin: 10px;
      color: black;
      text-decoration: none;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      padding: 10px;
      border-radius: 5px;
      background-color: var(--green-lighten-2);
      border: 1px solid var(--green-lighten-2);
   }
}
</style>