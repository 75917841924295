<template>
   <v-dialog v-model="open" max-width="500" persistent>
      <div class="admin-panel__dashboard__dialog">
         <p class="admin-panel__dashboard__dialog__title">
            {{ title }}
         </p>

         <slot></slot>
         <div class="admin-panel__dashboard__dialog__footer">
            <v-btn class="admin-panel__dashboard__dialog__footer-btn" @click="closeModal"> CANCEL </v-btn>
            <v-btn
               v-if="actionBtnText"
               :disabled="isActionBtnDisabled"
               type="submit"
               class="admin-panel__dashboard__dialog__footer-btn"
               @click="action"
            >
               {{ actionBtnText }}
            </v-btn>
         </div>
      </div>
   </v-dialog>
</template>

<script>
export default {
   name: 'AdminPanelDashboardDialog',
   props: {
      title: { type: String, default: 'Modal' },
      actionBtnText: { type: String, default: '' },
      action: { type: Function },
      isActionBtnDisabled: { type: Boolean, default: true },
   },
   data() {
      return {
         open: false,
         rules: {
            required: value => !!value || 'Required',
            min: value => value.length >= 8 || 'Min 8 characters',
            emailMatch: value => value.match(/.*@\w+\.\w{1,3}/g) || 'Email is not valid',
            phoneMatch: value => value.match(/^[0-9 ()+-]{4,16}$/) || 'Phone is not valid',
            time: value => value > 0 || 'Must be positive value and more then 0',
         },
         isFormValid: false,
      };
   },
   methods: {
      dialogOpen() {
         this.open = true;
      },

      closeModal() {
         this.open = false;
         this.$emit('close');
      },
   },
};
</script>

<style lang="scss">
.admin-panel__dashboard__dialog {
   width: 100%;
   height: auto !important;
   padding: 30px !important;
   background-color: var(--green-lighten-4) !important;
   &__title {
      font-family: 'Roboto';
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
   }
   &__body {
      width: 100%;
      &__item {
         font-family: 'Roboto';
         font-size: 16px;
         font-weight: 500;
      }
   }
   &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      &-btn {
         background-color: var(--green-lighten-2) !important;
      }
   }
}
</style>