<template>
   <v-dialog v-model="open" max-width="500" persistent>
      <div class="admin-panel__dashboard__dialog">
         <p class="admin-panel__dashboard__dialog__title">
            {{ titleText }}
         </p>

         <v-form
            class="admin-panel__dashboard__dialog__body"
            v-model="isFormValid"
            @keydown.native.enter="action"
            @submit.prevent="action"
            ref="form"
         >
            <template v-for="(item, key) in data.schema">
               <v-text-field
                  class="admin-panel__dashboard__dialog__body__item"
                  :key="key"
                  v-if="
                     key != 'user_name' &&
                     key != 'email' &&
                     key != 'password' &&
                     key != 'phone' &&
                     key != '_id' &&
                     key != 'questions' &&
                     key != 'time' &&
                     key != 'type'
                  "
                  v-model.trim="data.schema[key]"
                  :label="key[0].toUpperCase() + key.slice(1)"
               ></v-text-field>
            </template>

            <!-- SUBSCRIPTION TIME FIELDS -->
            <v-text-field
               :rules="[rules.time]"
               v-model="subscribtionTime"
               class="admin-panel__dashboard__dialog__body__item"
               type="number"
               v-if="data.schema.time !== undefined"
               label="Time"
            ></v-text-field>

            <v-select
               :rules="[rules.required]"
               v-if="data.schema.time !== undefined"
               v-model="subscribtionTimePeriod"
               class="admin-panel__dashboard__dialog__body__item"
               :items="['Minutes', 'Hours', 'Days', 'Weeks', 'Months', 'Years']"
               item-text="state"
               item-value="abbr"
               label="Time Period"
            ></v-select>

            <v-text-field
               class="admin-panel__dashboard__dialog__body__item"
               v-if="data.schema.user_name !== undefined"
               v-model.trim="data.schema.user_name"
               :rules="[rules.required]"
               label="User Name"
            ></v-text-field>
            <v-text-field
               class="admin-panel__dashboard__dialog__body__item"
               v-if="data.schema.email !== undefined"
               v-model.trim="data.schema.email"
               :rules="[rules.required, rules.emailMatch]"
               label="E-mail"
               hint="Email@gmail.com"
            ></v-text-field>
            <v-text-field
               class="admin-panel__dashboard__dialog__body__item"
               v-if="data.schema.phone !== undefined"
               v-model.trim="data.schema.phone"
               :rules="[rules.phoneMatch]"
               hint="+1 415 555 0132"
               label="Phone"
            ></v-text-field>
            <v-text-field
               class="admin-panel__dashboard__dialog__body__item"
               v-if="data.schema.password !== undefined"
               v-model.trim="data.schema.password"
               :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
               :rules="[rules.required, rules.min]"
               :type="showPassword ? 'text' : 'password'"
               hint="At least 8 characters"
               label="Password"
               @click:append="showPassword = !showPassword"
            ></v-text-field>
         </v-form>
         <div class="admin-panel__dashboard__dialog__footer">
            <v-btn class="admin-panel__dashboard__dialog__footer-btn" @click="open = false"> CANCEL </v-btn>
            <v-btn type="submit" class="admin-panel__dashboard__dialog__footer-btn" @click="action">
               {{ data.name }}
            </v-btn>
         </div>
      </div>
   </v-dialog>
</template>

<script>
export default {
   name: 'AdminPanelDashboardDialog',
   data() {
      return {
         open: false,
         showPassword: false,
         rules: {
            required: value => !!value || 'Required',
            min: value => value.length >= 8 || 'Min 8 characters',
            emailMatch: value => value.match(/.*@\w+\.\w{1,3}/g) || 'Email is not valid',
            phoneMatch: value => value.match(/^[0-9 ()+-]{4,16}$/) || 'Phone is not valid',
            time: value => value > 0 || 'Must be positive value and more then 0',
         },
         data: {
            name: '',
            schema: {},
            labels: {},
         },

         isFormValid: false,

         subscribtionTimePeriod: '',

         subscribtionTime: 0,
      };
   },
   computed: {
      routeName() {
         return this.$route.params.name;
      },

      isRoutNamePlural() {
         return this.routeName[this.routeName.length - 1] === 's';
      },

      titleText() {
         return this.isRoutNamePlural
            ? `Create ${this.routeName.slice(0, this.routeName.length - 1)}`.toUpperCase()
            : `Create ${this.routeName}`.toUpperCase();
      },
   },
   methods: {
      dialogOpen(data) {
         this.open = true;
         this.$nextTick(() => {
            this.data = data;
         });
      },
      async action() {
         let postValue = null;
         if (this.$route.params.name === 'admins') {
            const emailMatch = !!this.data.schema.email.match(/.*@\w+\.\w{1,3}/g);
            const passwordMatch = this.data.schema.password.length >= 8;
            if (emailMatch && passwordMatch) {
               postValue = this.data.schema;
            }
         } else if (this.$route.params.name === 'subscribtion') {
            postValue = this.data.schema;
            const timePeriod =
               this.subscribtionTimePeriod === 'Minutes'
                  ? 'm'
                  : this.subscribtionTimePeriod === 'Hours'
                  ? 'h'
                  : this.subscribtionTimePeriod === 'Days'
                  ? 'd'
                  : this.subscribtionTimePeriod === 'Month'
                  ? 'M'
                  : this.subscribtionTimePeriod === 'Weeks'
                  ? 'w'
                  : 'Y';
            postValue.time = `${this.subscribtionTime}${timePeriod}`;
         } else {
            if (this.$route.params.name === 'urers') {
               const emailMatch = !!this.data.schema.email.match(/.*@\w+\.\w{1,3}/g);
               const passwordMatch = this.data.schema.password.length >= 8;
               const phoneMatch = !!this.data.schema.phone.match(/\+d{11,13}/g);
               if ((emailMatch || phoneMatch) && passwordMatch) {
                  postValue = this.data.schema;
               }
            } else {
               postValue = this.data.schema;
            }
         }

         if (postValue) {
            try {
               await this.$http.post(
                  `/admin/resources/${
                     this.$route.params.name === 'subscribtion' ? 'subscription' : this.$route.params.name
                  }`,
                  postValue,
               );
               this.open = false;
            } catch (error) {
               console.log(error.response.data.message);
            } finally {
               this.$store.dispatch('admin/loadResources');
            }
         }
      },
   },
};
</script>

<style lang="scss">
.admin-panel__dashboard__dialog {
   width: 100%;
   height: auto !important;
   padding: 30px !important;
   background-color: var(--green-lighten-4) !important;
   &__title {
      font-family: 'Roboto';
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
   }
   &__body {
      width: 100%;
      &__item {
         font-family: 'Roboto';
         font-size: 16px;
         font-weight: 500;
      }
   }
   &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      &-btn {
         background-color: var(--green-lighten-2) !important;
      }
   }
}
</style>