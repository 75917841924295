<template>
   <v-dialog v-model="open" max-width="500" persistent>
      <div class="admin-panel__dashboard__dialog">
         <p class="admin-panel__dashboard__dialog__title">
            {{ label }}
         </p>
         <v-form class="admin-panel__dashboard__dialog__body">
            <v-file-input
               :accept="getAccept()"
               :label="getLabel()"
               v-model="data.filename"
               :rules="[rules.required]"
               :multiple="isMultiple()"
            ></v-file-input>
         </v-form>
         <div class="admin-panel__dashboard__dialog__footer">
            <v-btn class="admin-panel__dashboard__dialog__footer-btn" @click="open = false"> CANCEL </v-btn>
            <v-btn class="admin-panel__dashboard__dialog__footer-btn" @click="action">
               {{ data.button.label }}
            </v-btn>
         </div>
      </div>
      <div v-if="fileLoading" class="loading">
         <div class="spin-wrapper">
            <div class="spinner"></div>
         </div>
      </div>
   </v-dialog>
</template>

<script>
export default {
   name: 'AdminPanelDashboardDialogFile',
   data() {
      return {
         open: false,
         rules: {
            required: value => !!value || 'Required',
         },
         data: {
            button: { name: 'Upload' },
         },
         fileLoading: false,
      };
   },
   computed: {
      titleText() {
         let text = `${this.data.button.name} ${this.$route.params.name.slice(
            0,
            this.$route.params.name.length - 1,
         )}`.toUpperCase();
         return text;
      },
      label() {
         if (this.titleText.toLowerCase() === 'upload categorie') {
            return 'Upload XLSX Categories';
         } else if (this.titleText.toLowerCase() === 'media categorie') {
            return 'UPLOAD MEDIA';
         } else {
            return this.titleText;
         }
      },
   },
   methods: {
      dialogOpen(data) {
         this.data = data;
         this.open = true;
      },
      isMultiple() {
         return this.data.button.multiple;
      },
      getAccept() {
         return this.data.button.accept;
      },
      getLabel() {
         return this.data.button.label;
      },
      async action() {
         this.fileLoading = true;
         let formData = new FormData();
         let content = this.data.filename;

         if (this.data.item['_id'] != undefined) {
            formData.append('_id', this.data.item._id);
         }

         if (this.data.button.multiple) {
            for (var i = 0; i < content.length; i++) {
               formData.append(this.data.button.fileTitle, content[i]);
            }
         } else {
            formData.append(this.data.button.fileTitle, content, content.name);
         }

         try {
            if (this.data.button.method == 'put') {
               await this.$http.put(`/admin` + this.data.button.link, formData, {
                  headers: {
                     'Content-Type': 'multipart/form-data',
                  },
               });
            }

            if (this.data.button.method == 'post') {
               await this.$http.post(`/admin` + this.data.button.link, formData, {
                  headers: {
                     'Content-Type': 'multipart/form-data',
                  },
               });
            }
            this.fileLoading = false;
            this.open = false;
         } catch (error) {
            console.log(error);
            console.log(error.response.data.message);
         } finally {
            this.$store.dispatch('admin/loadResources');
         }
      },
   },
};
</script>

<style lang="scss">
.admin-panel__dashboard__dialog {
   width: 100%;
   height: auto !important;
   padding: 30px !important;
   background-color: var(--green-lighten-4) !important;
   &__title {
      font-family: 'Roboto';
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
   }
   &__body {
      width: 100%;
      &__item {
         font-family: 'Roboto';
         font-size: 16px;
         font-weight: 500;
      }
   }
   &__footer {
      display: flex;
      justify-content: space-between;

      align-items: center;
      margin-top: 10px;
      &-btn {
         background-color: var(--green-lighten-2) !important;
      }
   }
}
.loading {
   position: absolute;
   background: rgba($color: #000000, $alpha: 0.5);
   width: 100%;
   height: 100%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.spin-wrapper {
   position: relative;
   width: 100%;
   height: 100%;

   .spinner {
      position: absolute;
      height: 60px;
      width: 60px;
      border: 3px solid transparent;
      border-top-color: #a04668;
      top: 50%;
      left: 50%;
      margin: -30px;
      border-radius: 50%;
      animation: spin 2s linear infinite;

      &:before,
      &:after {
         content: '';
         position: absolute;
         border: 3px solid transparent;
         border-radius: 50%;
      }

      &:before {
         border-top-color: #254e70;
         top: -12px;
         left: -12px;
         right: -12px;
         bottom: -12px;
         animation: spin 3s linear infinite;
      }

      &:after {
         border-top-color: #fffbfe;
         top: 6px;
         left: 6px;
         right: 6px;
         bottom: 6px;
         animation: spin 4s linear infinite;
      }
   }
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
</style>